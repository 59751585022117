/* ==========================================================================
   NBT css main file
   ========================================================================== */

/* ==========================================================================
   Font face
   ========================================================================== */

@font-face {
	font-family: 'Oblik-Light';
	src: url('../fonts/Oblik-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Oblik-Light.otf')  format('opentype'),
	url('../fonts/Oblik-Light.woff') format('woff'), url('../fonts/Oblik-Light.ttf')  format('truetype'), url('../fonts/Oblik-Light.svg#Oblik-Light') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Oblik-Bold';
	src: url('../fonts/Oblik-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/Oblik-Bold.otf')  format('opentype'),
	url('../fonts/Oblik-Bold.woff') format('woff'), url('../fonts/Oblik-Bold.ttf')  format('truetype'), url('../fonts/Oblik-Bold.svg#Oblik-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Oblik-Bold-Italic';
	src: url('../fonts/OblikBoldItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/OblikBoldItalic.otf')  format('opentype'),
	url('../fonts/OblikBoldItalic.woff') format('woff'), url('../fonts/OblikBoldItalic.ttf')  format('truetype'), url('../fonts/OblikBoldItalic.svg#OblikBoldItalic') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* ==========================================================================
   NBT styles
   ========================================================================== */

body {
	background: #f9f9f9;
	font-family: Oblik-Light;
	text-align: left;
	text-shadow: none !important;
	color: #323232;
	padding-right:0px !important;
	margin-right:0px !important;
}

body.modal-open {
	overflow: auto;
}

label {
	font-family: Oblik-Bold;
	color: #323232;
	font-weight: normal !important;
}

a {
	color: #323232;
}

a.link {
	color: #e70d2f;
	font-weight: bold;
	text-decoration: none;
}

a:hover {
	color: #323232;
	text-decoration: underline;
}

@media (min-width: 1384px) {
	.container-wide {
		width: 1366px;
	}
}

#navbar {
	margin-top: 10px;
	border: 0;
}

@media (min-width: 1200px) {
	#navbar {
		margin-top: 20px;
	}
}

.navbar {
	min-height: 50px;
	margin: 0;
	border: none;
	position: absolute;
	top: 0;
	z-index: 99;
	width: 100%;
	margin-top: 40px;
	z-index: 9999;
}

@media (max-width: 768px) {
	.navbar {
		margin-top: 10px;
	}
}

@media (max-width: 991px) {
	.navbar-default .navbar-nav>li>a {
		text-align: center;
	}
	.navbar-default .navbar-nav>li>span {
		text-align: center;
	}
}

.navbar-default {
	background: none;
	border: none;
}

@media (max-width: 991px) {
	.navbar-nav {
		background: #fff;
		border: none;
		z-index: 9999;
	}
}

.navbar-default .navbar-toggle {
	background-color: #e6e6e5;
}

.navbar-default .navbar-toggle {
	border-color: #e6e6e5;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #c9142e;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
	color: #323232;
	background: none;
}

.navbar-default .navbar-nav>li>a {
	font-family: Oblik-Bold;
	font-size: 12px;
	text-transform: uppercase;
	color: #323232;
	border-radius: 50px;
	padding: 10px 15px
}

.navbar-default .navbar-nav>li>span {
	font-family: Oblik-Bold;
	font-size: 12px;
	text-transform: uppercase;
	color: #323232;
	border-radius: 50px;
	padding: 10px 15px;
	line-height: 20px;
	display: block;
	position: relative;
	cursor: pointer;
}

.navbar-default .navbar-nav>li>a:hover {
	color: #323232;
	border-radius: 50px;
	border-left: 2px solid #e70d2f;
	border-right: 2px solid #e70d2f;
	padding: 10px 13px
}

.navbar-default .navbar-nav>li>span:hover {
	color: #323232;
	border-radius: 50px;
	border-left: 2px solid #e70d2f;
	border-right: 2px solid #e70d2f;
	padding: 10px 13px
}

@media (min-width: 991px) {
	.navbar-default .navbar-nav>li>a {
		font-family: Oblik-Bold;
		font-size: 8px;
		text-transform: uppercase;
		color: #323232;
		border-radius: 50px;
		padding: 10px 15px;
		text-shadow: 0 0 4px rgba(255,255,255,1);
	}

	.navbar-default .Snavbar-nav>li>a:hover {
		color: #323232;
		border-radius: 4px;
		border-left: 1px solid #e70d2f;
		border-right: 1px solid #e70d2f;
		padding: 10px 0px
	}
	.navbar-default .navbar-nav>li>span {
		font-family: Oblik-Bold;
		font-size: 8px;
		text-transform: uppercase;
		color: #323232;
		border-radius: 50px;
		padding: 10px 15px;
		text-shadow: 0 0 4px rgba(255,255,255,1);
	}

	.navbar-default .Snavbar-nav>li>span:hover {
		color: #323232;
		border-radius: 4px;
		border-left: 1px solid #e70d2f;
		border-right: 1px solid #e70d2f;
		padding: 10px 0px
	}
}

@media (min-width: 1200px) {
	.navbar-default .navbar-nav>li>a {
		font-family: Oblik-Bold;
		font-size: 10px;
		text-transform: uppercase;
		color: #323232;
		border-radius: 50px;
		padding: 10px 15px;
		text-shadow: none;
	}

	.navbar-default .Snavbar-nav>li>a:hover {
		color: #323232;
		border-radius: 30px;
		border-left: 2px solid #e70d2f;
		border-right: 2px solid #e70d2f;
		padding: 10px 8px
	}
	.navbar-default .navbar-nav>li>span {
		font-family: Oblik-Bold;
		font-size: 10px;
		text-transform: uppercase;
		color: #323232;
		border-radius: 50px;
		padding: 10px 15px;
		text-shadow: none;
	}

	.navbar-default .Snavbar-nav>li>span:hover {
		color: #323232;
		border-radius: 30px;
		border-left: 2px solid #e70d2f;
		border-right: 2px solid #e70d2f;
		padding: 10px 8px
	}
}

@media (min-width: 1384px) {
	.navbar-default .navbar-nav>li>a {
		font-family: Oblik-Bold;
		font-size: 12px;
		text-transform: uppercase;
		color: #323232;
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 50px;
		padding: 10px 15px;
		margin-right: 3px;
	}

	.navbar-default .navbar-nav>li>a:hover {
		color: #323232;
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 50px;
		border-left: 2px solid #e70d2f;
		border-right: 2px solid #e70d2f;
		padding: 10px 13px
	}
	.navbar-default .navbar-nav>li>span {
		font-family: Oblik-Bold;
		font-size: 12px;
		text-transform: uppercase;
		color: #323232;
		border-radius: 50px;
		padding: 10px 15px
	}

	.navbar-default .navbar-nav>li>span:hover {
		color: #323232;
		border-radius: 50px;
		border-left: 2px solid #e70d2f;
		border-right: 2px solid #e70d2f;
		padding: 10px 13px
	}
}

.main-logo {
	width: 180px;
	height: 60px;
}

.kolejna-lokalizacja {
	margin-top: 5px;
	padding-top: 20px;
	border-top: 1px solid #d1d1d1;
}

@media (max-width: 768px) {
	.main-logo {
		margin-left: 20px;
	}
}

label span {
	font-family: Oblik-Light;
	font-weight: normal;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-top-40 {
	margin-top: 40px;
}
.sekcja-wyszukiwania .nbt-slider {
	margin-top: 20px;
}
.sekcja-wyszukiwania .addremove {
	margin-top: 25px;
}

#toggle-sidebar {
	margin: 15px;
}

/*SPIN BUTTON*/

.spinner input {
	text-align: right;
}

.input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	width: 2%;
	vertical-align: middle;
	display: table-cell;
}

.input-group-btn-vertical > .btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 8px;
	margin-left: -1px;
	position: relative;
	border-radius: 0;
}

.input-group-btn-vertical > .btn:first-child {
	border-top-right-radius: 4px;
}

.input-group-btn-vertical > .btn:last-child {
	margin-top: -2px;
	border-bottom-right-radius: 4px;
}

.input-group-btn-vertical i {
	position: absolute;
	top: 0;
	left: 4px;
}

/* override bootstrap 3 class to remove scrollbar from modal backdrop
   when not necessary */
.modal {
	overflow-y: auto;
}
/* custom class to override .modal-open */
.modal-noscrollbar {
	margin-right: 0 !important;
}

#nbtCarousel {
	position: relative;
	margin-top: 0;

	height: 550px;
}

.carousel-inner{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.carousel-inner .item{
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

#main-image {
	position: relative;
	margin-top: 0;
	width: 100%;
	height: 350px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@media (max-width: 768px) {
	#main-image {
		margin-top: 40px;
	}
}

h1.main-heading {
	text-transform: uppercase;
}

h1.main-heading span {
	font-family: Oblik-Light;
}

@media (max-width: 768px) {
	#nbtCarousel {
		margin-top: 0;
	}
}

.carousel-indicators li {
	display: inline-block;
	width: 85px;
	height: 2px;
	margin: 0;
	text-indent: -999px;
	cursor: pointer;
	background-color: #b2b7be;
	border: none;
	border-radius: 0;
}

.carousel-indicators .active {
	display: inline-block;
	width: 85px;
	height: 4px;
	margin: 0;
	text-indent: -999px;
	cursor: pointer;
	background-color: #e70d2f;
	border: none;
	border-radius: 0;
}

@media screen and (min-width: 768px) {
	.carousel-indicators {
		bottom: 40px;
	}
}

@media screen and (min-width: 991px) {
	.carousel-indicators {
		bottom: 45px;
	}
}

@media screen and (min-width: 1200px) {
	.carousel-indicators {
		bottom: 50px;
	}
}

@media screen and (min-width: 1400px) {
	.carousel-indicators {
		bottom: 70px;
	}
}

@media screen and (min-width: 1600px) {
	.carousel-indicators {
		bottom: 110px;
	}
}

.main-text {
	position: absolute;
	top: 40%;
	z-index: 999;
	max-width: 700px;
	font-family: Oblik-Bold;
}

.main-text h1 {
	font-family: Oblik-Bold;
	text-align: left;
	font-size: 20px;
	text-shadow: none !important;
	color: #323232;
	z-index: 100;
}

.main-text p {
	font-family: Oblik-Bold;
	text-align: left;
	text-shadow: none !important;
	color: #323232;
	z-index: 100;
	font-size: 11px;
	margin-bottom: 10px;
}

@media only screen and (min-width : 768px) {
	.main-text h1 {
		font-size: 25px;
	}
	.main-text p {
		font-size: 14px;
		font-family: Oblik-Bold;
		text-align: left;
		text-shadow: none !important;
		color: #323232;
		z-index: 100;
	}
}
@media only screen and (min-width : 992px) {
	.main-text h1 {
		font-size: 35px;
	}
}

@media only screen and (min-width : 1100px) {
	.main-text h1 {
		font-size: 40px;
	}
}

@media only screen and (min-width : 1200px) {
	.main-text h1 {
		font-size: 45px;
	}
}

@media only screen and (min-width : 1400px) {
	.main-text h1 {
		font-size: 55px;
	}
}

.item {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.img-responsive, .thumbnail>img, .thumbnail a>img, .carousel-inner>.item>img, .carousel-inner>.item>a>img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0 auto;
}

#footer {
	margin-top: 40px;
	margin-bottom: 20px;
}

.footer-first {
	border-top: 1px solid #d1d1d1;
	border-bottom: 1px solid #d1d1d1;
	margin-bottom: 30px;
	padding: 10px 0;
}

.footer-second {
	padding: 0 15px 20px;
	text-align: center;
}

a.footer-link {
	font-family: Oblik-Bold;
	font-size: 12px;
	margin: 0 5px;
	color: #323232;
	text-decoration: none;
	text-transform: uppercase;
}

@media only screen and (min-width : 768px) {
	a.footer-link {
		font-family: Oblik-Bold;
		font-size: 12px;
		margin: 0 20px;
		color: #323232;
		text-decoration: none;
		text-transform: uppercase;
	}
}

a.footer-link:hover {
	color: #000;
	text-decoration: none;
}

.footer-third {
	padding: 0 15px;
}

.copyright-text {
	font-family: Oblik-Light;
	font-size: 12px;
	color: #323232;
	line-height: 54px;
	vertical-align: baseline;
	display: inline-block;
}

.footer-logo {
	max-width: 160px;
	margin: 0 5px;
	vertical-align: baseline;
	display: inline-block;
}

@media (max-width: 768px) {
	.footer-logo {
		max-width: 120px;
		margin: 0 5px;
		vertical-align: baseline;
		display: inline-block;
	}
	.copyright-text {
		text-align: center !important;
		display: block;
	}
	.social-media {
		text-align: center !important;
	}
}
.social-media {
	margin-top: 10px;
	text-align: right;
}

@media only screen and (min-width : 768px) {
	.social-media {
		margin-top: 30px;
		text-align: right;
	}
}

.social-media a {
	font-size: 16px;
	color: #323232;
	margin: 0 10px;
}

.social-media a:hover {
	color: #0357e3;
}

.modal {
	z-index: 9999;
}

.modal-content {
	position: relative;
	background-color: rgba(255, 255, 255, 0.9);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: none !important;
	border-radius: 6px;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

@media (min-width: 768px) {
	.modal-content {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

.modal-title {
	font-family: Oblik-Bold;
	color: #323232;
}

.modal-body p {
	font-family: Oblik-Light;
	color: #323232;
}

.btn-primary {
	color: #fff;
	background-color: #c9142e;
	border: none !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
	color: #fff;
	background-color: #a41126;
	border: none !important;
}

.btn-default {
	border: none !important;
}

.btn {
	font-family: Oblik-Bold !important;
	margin-bottom: 5px;
}

#registerModal button.register {
	width: 100% !important;
	margin: 10px 0;
}

.register-buttons {
	padding: 0 10px;
}

@media only screen and (min-width : 768px) {
	.register-buttons {
		padding: 0 60px;
	}
}
@media only screen and (min-width : 992px) {

}
@media only screen and (min-width : 1200px) {

}

/* ==========================================================================
   main page content
   ========================================================================== */

.nopadding {
	padding: 0;
}

.link-row {
	cursor: pointer;
}

#main-page-boxes {
	margin-top: -50px;
}

@media only screen and (min-width : 768px) {
	#main-page-boxes {
		margin-top: -50px;
	}
}
@media only screen and (min-width : 992px) {
	#main-page-boxes {
		margin-top: -50px;
	}
}
@media only screen and (min-width : 1200px) {
	#main-page-boxes {
		margin-top: -50px;
	}
}

@media only screen and (min-width : 1400px) {
	#main-page-boxes {
		margin-top: -70px;
	}
}

@media only screen and (min-width : 1600px) {
	#main-page-boxes {
		margin-top: -100px;
	}
}

#list-ministra {
	display: none;
	background: url("../img/list_przewodni_ministra.png");
	background-size: 978px 350px;
	background-position: bottom right;
	background-repeat: no-repeat;
	min-height: 350px;
	padding-top: 205px;
}

#list-ministra h3, #list-ministra h3 span {
	color: #fff !important;
	text-shadow: 0px 0px 6px rgba(0, 0, 0, 1) !important;
}

@media only screen and (min-width : 1200px) {
	#list-ministra h3, #list-ministra h3 span {
		color: #fff !important;
		text-shadow: none;
	}
}

#analiza {
	background: #cecece;
	min-height: 600px;
	padding-top: 30px;
	margin-top: 50px;
}

#calculator {
	margin-top: 0;
	background: #fece15;
	background-image: url("../img/kalkulator_bg.jpg");
	background-position: top left;
	background-repeat: no-repeat;
	min-height: 300px;
	padding-top: 30px;
}

#faq {
	background: #ef304e;
	background-image: url("../img/faq_bg.jpg");
	background-position: top right;
	background-repeat: no-repeat;
	min-height: 300px;
	padding-top: 30px;
}

#idea {
	background: #262e45;
	min-height: 300px;
	padding-top: 30px;
}

.box-title {
	font-family: Oblik-Bold;
	font-size: 30px;
	color: #fff !important;
	text-transform: uppercase;
	margin-left: 25px;
	margin-right: 25px;
}

.box-title span {
	font-family: Oblik-Light;
	color: #fff !important;
}

.analiza-icon {
	/*padding-bottom: 89px;*/
	margin-left: 25px;
	margin-right: 25px;
}

.faq-icon {
	padding-bottom: 89px;
	margin-left: 25px;
	margin-right: 25px;
}

.kalkulator-icon {
	float: right;
	padding-bottom: 89px;
	margin-left: 25px;
	margin-right: 25px;
}

.box-title.kalkulator {
	text-align: right;
}

.idea-icon {
	float: right;
	padding-bottom: 116px;
	margin-left: 25px;
	margin-right: 25px;
}

.box-title.idea {
	text-align: right;
}

@media only screen and (min-width : 768px) {
	.faq-icon {
		padding-bottom: 56px;
	}
	#calculator {
		margin-top: 50px;
		background: #fece15;
		background-image: url("../img/kalkulator_bg.jpg");
		background-position: top left;
		background-repeat: no-repeat;
		min-height: 600px;
		padding-top: 30px;
	}
	.kalkulator-icon {
		float: right;
		padding-bottom: 350px;
		margin-left: 25px;
		margin-right: 25px;
	}
}
@media only screen and (min-width : 992px) {
	.faq-icon {
		padding-bottom: 89px;
	}
	.kalkulator-icon {
		float: right;
		padding-bottom: 350px;
		margin-left: 25px;
		margin-right: 25px;
	}
}
@media only screen and (min-width : 1200px) {
	.kalkulator-icon {
		float: right;
		padding-bottom: 350px;
		margin-left: 25px;
		margin-right: 25px;
	}
}

/* ==========================================================================
   other pages eg. contact, cookie policy etc.
   ========================================================================== */

#content {
	margin-top: 25px;
}

#content h1, h2, h3, h4, h5 {
	font-family: "Oblik-Bold";
	color: #323232;
}

#content h1 span, h2 span, h3 span, h4 span, h5 span {
	font-family: "Oblik-Light";
	color: #323232;
}

#content p {
	font-family: Oblik-Light;
	color: #323232;
}

label.error {
	font-family: Oblik-Light;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: normal;
	color: red;
}

#plec-error {
	color: #a94442 !important;
}

#content .form-control {
	color: #323232;
	background-color: #f2f2f2;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
	font-family: Oblik-Bold;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
	color: #323232;
}

.has-success .help-block {
	color: #323232 !important;
}

.has-warning .help-block {
	color: #7b784c !important;
}

.has-error .help-block {
	color: #a94442 !important;
}

.form-group.has-success .form-control {
	color: #323232;
	border: none;
	border-radius: 8px;
}

.form-group.has-warning .form-control {
	color: #323232;
	background-color: #f1f0e2 !important;
	border: none;
	border-radius: 8px;
}

.form-group.has-error .form-control {
	color: #323232;
	background-color: #eddddd !important;
	border: none;
	border-radius: 8px;
}

.has-success .checkbox label {
	color: #323232;
}

.has-warning .checkbox label {
	color: #7b784c;
}

.has-error .checkbox label {
	color: #a94442;
}

#content .form-control:focus {
	color: #323232;
	background-color: #f2f2f2;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
}

.nbt-modal .form-control {
	color: #323232;
	background-color: #fff;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
	font-family: Oblik-Bold;
}

.osoba-grupy {
	margin: 5px 0;
}

.nbt-modal .bootstrap-select > .dropdown-toggle.bs-placeholder,
.nbt-modal .bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.nbt-modal .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.nbt-modal .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	color: #999;
	background: #fff;
}

.nbt-modal .form-control:focus {
	color: #323232;
	background-color: #fff;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
}

.menu-left {
	list-style: none;
	border-top: 1px solid #d1d1d1;
	margin: 0 60px 0 0;
	padding: 0;
}

.menu-left li {
	padding: 30px 0 30px 0;
	border-bottom: 1px solid #d1d1d1;
}

.menu-left li a, .menu-left li span {
	font-family: Oblik-Bold;
	cursor: pointer;
}

.menu-left li a:hover, .menu-left li span:hover {
	text-decoration: none;
}

.menu-left-title {
	font-family: Oblik-Light !important;
}

.upload-btn {
	font-family: Oblik-Bold;
	color: #e70d2f;
	cursor: pointer;
	margin-bottom: 10px;
}

.upload-btn:hover {
	text-decoration: none;
}

.delete-btn {
	font-family: Oblik-Bold;
	color: #323232;
	cursor: pointer;
	margin-bottom: 10px;
}

.delete-btn:hover {
	text-decoration: none;
}

.zakres-input {
	border: none;
	background: none;
	font-family: Oblik-Bold;
	color: #e70d2f;
	font-size: 20px;
	width: 90px;
	padding: 0;
	margin: 0;
}

.zakres-label {
	font-family: Oblik-Bold;
	color: #323232;
	font-size: 20px;
}

.ui-slider {
	margin: 15px 0;
}

.btn-info {
	color: #fff;
	background-color: #323232 !important;
	border: none !important;
}

.btn-info:hover, .btn-info:focus, .btn-info.focus, .btn-info:active, .btn-info.active, .open>.dropdown-toggle.btn-info {
	color: #fff;
	background-color: #323232 !important;
	border: none !important;
}

#register .row {
	margin-top: 10px;
	margin-bottom: 10px;
}

.icon-jfi-trash .jFiler-item-trash-action {
	text-decoration: none !important;
}

.bootstrap-select > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	color: #999;
	background: #f2f2f2;
}

.has-error .bootstrap-select > .dropdown-toggle.bs-placeholder,
.has-error .bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.has-error .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.has-error .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	color: #999;
	background: #eddddd !important;
}

.has-warning .bootstrap-select > .dropdown-toggle.bs-placeholder,
.has-warning .bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.has-warning .bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.has-warning .bootstrap-select > .dropdown-toggle.bs-placeholder:active {
	color: #999;
	background: #f1f0e2 !important;
}

#kalkulator-form div.row {
	margin-top: 15px;
	margin-bottom: 15px;
}

#wybrana-grupa {
	display: block;
	height: 45px;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

#wyniki div.row {
	margin-top: 15px;
	margin-bottom: 15px;
}

#wykresy div.row {
	margin-top: 15px;
	margin-bottom: 15px;
}

.alert-success {
	color: #262e45;
	background-color: #ededed;
	border-color: #e2e2e2;
}

.sekcja-wyszukiwania {
	margin-top: 5px;
	margin-bottom: 10px;
}

.sekcja-wyszukiwania:after {
	clear: both !important;
}

/* ==========================================================================
   Navbar collapse to mobile at 991 px styles
   ========================================================================== */
@media (max-width: 992px) {
	.navbar-header {
		float: none;
	}
	.navbar-left,.navbar-right {
		float: none !important;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}
	.navbar-fixed-top {
		top: 0;
		border-width: 0 0 1px;
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-nav {
		float: none!important;
		margin-top: 7.5px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.collapse.in{
		display:block !important;
	}
}

/* ==========================================================================
   Login
   ========================================================================== */

@media (min-width: 768px) {
	.nbt_row-sm-offset-3 div:first-child[class*="col-"] {
		margin-left: 25%;
	}
}

.nbt_login .nbt_loginOr {
	font-family: Oblik-Light;
	position: relative;
	font-size: 1.5em;
	color: #aaa;
	margin-top: 1em;
	margin-bottom: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}
.nbt_login .nbt_loginOr .nbt_hrOr {
	font-family: Oblik-Light;
	background-color: #cdcdcd;
	height: 1px;
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}
.nbt_login .nbt_loginOr .nbt_spanOr {
	font-family: Oblik-Light;
	display: block;
	position: absolute;
	left: 50%;
	top: -0.6em;
	margin-left: -1.5em;
	background-color: white;
	width: 3em;
	text-align: center;
}

.nbt_login .nbt_loginForm .input-group.i {
	width: 2em;
}
.nbt_login .nbt_loginForm  .help-block {
	color: red;
}

.btn.dropdown-toggle.btn-default.bs-placeholder {
	color: #999999;
}

.btn.dropdown-toggle.btn-default {
	color: #323232;
	background-color: #f2f2f2;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
	font-family: Oblik-Bold;
}

.modal .btn.dropdown-toggle.btn-default {
	color: #323232;
	background-color: #fff;
	background-image: none;
	border: none;
	border-radius: 8px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline: none;
	-moz-box-shadow: none !important;
	font-family: Oblik-Bold;
}

@media (min-width: 768px) {
	.nbt_login .nbt_forgotPwd {
		font-family: Oblik-Light;
		text-align: right;
		margin-top:10px;
	}
}

.login-btn {
	margin-top: 20px;
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
	font-family: Oblik-Light;
}

.btn-primary.disabled, .btn-primary[disabled], fieldset[disabled] .btn-primary, .btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary.focus[disabled], fieldset[disabled] .btn-primary.focus, .btn-primary.disabled:active, .btn-primary[disabled]:active, fieldset[disabled] .btn-primary:active, .btn-primary.disabled.active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary.active {
	background-color: #9ba2a2;
	border-color: #2e6da4;
}

/* ==========================================================================
   Checkbox and radio buttons
   ========================================================================== */

.checkbox {
	padding-left: 20px;
}
.checkbox label {
	font-family: "Oblik-Bold";
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
	color: #323232;
}
.checkbox label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
	transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	left: 0;
	top: 0;
	margin-left: -20px;
	padding-left: 3px;
	padding-top: 1px;
	font-size: 11px;
	color: #555555;
}
.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
	opacity: 0;
	z-index: 1;
	cursor: pointer;
}
.checkbox input[type="checkbox"]:focus + label::before,
.checkbox input[type="radio"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
	font-family: "FontAwesome";
	content: "\f00c";
}
.checkbox input[type="checkbox"]:indeterminate + label::after,
.checkbox input[type="radio"]:indeterminate + label::after {
	display: block;
	content: "";
	width: 10px;
	height: 3px;
	background-color: #555555;
	border-radius: 2px;
	margin-left: -16.5px;
	margin-top: 7px;
}
.checkbox input[type="checkbox"]:disabled,
.checkbox input[type="radio"]:disabled {
	cursor: not-allowed;
}
.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
	opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
	background-color: #eeeeee;
	cursor: not-allowed;
}
.checkbox.checkbox-circle label::before {
	border-radius: 50%;
}
.checkbox.checkbox-inline {
	margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
	background-color: #337ab7;
	border-color: #337ab7;
}
.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
	color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
	background-color: #d9534f;
	border-color: #d9534f;
}
.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
	color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
	background-color: #5bc0de;
	border-color: #5bc0de;
}
.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
	color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}
.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
	color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
	background-color: #5cb85c;
	border-color: #5cb85c;
}
.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
	color: #fff;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
	background-color: #337ab7;
	border-color: #337ab7;
}

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
	background-color: #fff;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
	background-color: #d9534f;
	border-color: #d9534f;
}

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
	background-color: #fff;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
	background-color: #5bc0de;
	border-color: #5bc0de;
}

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
	background-color: #fff;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
	background-color: #f0ad4e;
	border-color: #f0ad4e;
}

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
	background-color: #fff;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
	background-color: #5cb85c;
	border-color: #5cb85c;
}

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
	background-color: #fff;
}

.radio {
	padding-left: 20px;
}
.radio label {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	padding-left: 5px;
}
.radio label::before {
	content: "";
	display: inline-block;
	position: absolute;
	width: 17px;
	height: 17px;
	left: 0;
	margin-left: -20px;
	border: 1px solid #cccccc;
	border-radius: 50%;
	background-color: #fff;
	-webkit-transition: border 0.15s ease-in-out;
	-o-transition: border 0.15s ease-in-out;
	transition: border 0.15s ease-in-out;
}
.radio label::after {
	display: inline-block;
	position: absolute;
	content: " ";
	width: 11px;
	height: 11px;
	left: 3px;
	top: 3px;
	margin-left: -20px;
	border-radius: 50%;
	background-color: #555555;
	-webkit-transform: scale(0, 0);
	-ms-transform: scale(0, 0);
	-o-transform: scale(0, 0);
	transform: scale(0, 0);
	-webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	-o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
	transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
	opacity: 0;
	z-index: 1;
	cursor: pointer;
}
.radio input[type="radio"]:focus + label::before {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.radio input[type="radio"]:checked + label::after {
	-webkit-transform: scale(1, 1);
	-ms-transform: scale(1, 1);
	-o-transform: scale(1, 1);
	transform: scale(1, 1);
}
.radio input[type="radio"]:disabled {
	cursor: not-allowed;
}
.radio input[type="radio"]:disabled + label {
	opacity: 0.65;
}
.radio input[type="radio"]:disabled + label::before {
	cursor: not-allowed;
}
.radio.radio-inline {
	margin-top: 0;
}

.radio-primary input[type="radio"] + label::after {
	background-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::before {
	border-color: #337ab7;
}
.radio-primary input[type="radio"]:checked + label::after {
	background-color: #337ab7;
}

.radio-danger input[type="radio"] + label::after {
	background-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::before {
	border-color: #d9534f;
}
.radio-danger input[type="radio"]:checked + label::after {
	background-color: #d9534f;
}

.radio-info input[type="radio"] + label::after {
	background-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::before {
	border-color: #5bc0de;
}
.radio-info input[type="radio"]:checked + label::after {
	background-color: #5bc0de;
}

.radio-warning input[type="radio"] + label::after {
	background-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::before {
	border-color: #f0ad4e;
}
.radio-warning input[type="radio"]:checked + label::after {
	background-color: #f0ad4e;
}

.radio-success input[type="radio"] + label::after {
	background-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::before {
	border-color: #5cb85c;
}
.radio-success input[type="radio"]:checked + label::after {
	background-color: #5cb85c;
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
	font-family: 'FontAwesome';
	content: "\f00c";
}
input[type="checkbox"] .styled:checked + label::before,
input[type="radio"] .styled:checked + label::before {
	color: #fff;
}
input[type="checkbox"] .styled:checked + label::after,
input[type="radio"] .styled:checked + label::after {
	color: #fff;
}
